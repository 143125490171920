import useJwt from '@/auth/jwt/useJwt'
import { API_ROUTES } from '../config/routes/api'

export default {
	find(id) {
		return useJwt.get(`${API_ROUTES.patient.find}/${id}`)
	},
	getWithDietType(id) {
		return useJwt.get(`${API_ROUTES.patient.get_with_diet_type}/${id}`)
	},
	getOrderSios(date, location, type) {
		return useJwt.get(`${API_ROUTES.patient.get_order_sios}?date=${date}&location_id=${location}&food_type=${type}`)
	},
}
